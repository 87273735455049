<template>
  <div class="basic-table">
    <div class="actions">
      <PassportButton variant="accent" @click="downloadExcel" label="Download Excel File" />
      <PassportButton
        variant="danger"
        label="Delete Selected Users"
        :disabled="selectedRows.length <= 0"
        @click="deleteRows(selectedRows)"
      />
    </div>
    <h1 class="page-heading">{{ heading }}</h1>
    <hr class="line-break" />
    <DataTable
      :tableData="users"
      :headers="headers"
      :loading="showSkeleton"
      :heading="heading.toLowerCase()"
      :selectedRows.sync="selectedRows"
      ref="dataTable"
      :defaultSort="{ prop: 'username', order: 'ascending' }"
      searchable
      @row-click="handleRowClick"
    >
      <template v-slot:search>
        <SearchIcon class="search-icon" />
        <PassportInput v-bind:placeholder="'Search ' + heading + '...'" @input="search" />
      </template>
      <template v-slot:verified="props">
        <div class="action-row"></div>
        <Checkmark v-if="props.row.verified === true" class="checkmark" />
        <XIcon v-else class="x-icon" />
      </template>
      <template v-slot:points="props">
        <div class="action-row"></div>
        {{ props.row.points }}
      </template>
      <template v-slot:optIn="props">
        {{ props.row.optIn ? 'Yes' : 'No' }}
      </template>
      <template v-slot:expand="props">
        <DataTable :tableData="props.row.nestedData" :headers="nestedHeaders" :loading="showSkeleton" />
      </template>
    </DataTable>
    <!-- <VW
      class="pagination"
      v-model="currentPage"
      :totalItems="totalRecords"
      :perPage="perPage"
      @input="callForData($event)"
    /> -->
    <VW class="pagination" v-model="currentPage" :totalItems="totalRecords" :perPage="perPage" />
    <ModalDelete
      ref="deleteModal"
      :deleteCount="selectedRows.length"
      :icon="() => import('@/assets/icons/nav_assets_v2.svg')"
      @cancel="selectedRows = []"
    />
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import { ApiService } from '@/services/api.service';
import Checkmark from '@/assets/icons/checkmark.svg';
import VW from '@/components/VWPagination.vue';
import xlsx from 'json-as-xlsx';
import DataTable from '@/components/DataTable.vue';
import PassportInput from '@/components/PassportInput.vue';
import ModalDelete from '@/components/ModalDelete.vue';
import XIcon from '@/assets/icons/x_icon_red.svg';
import SearchIcon from '@/assets/icons/search_icon.svg';

export default {
  name: 'UserTable',
  components: {
    DataTable,
    PassportButton,
    PassportInput,
    ModalDelete,
    Checkmark,
    SearchIcon,
    XIcon,
    VW,
  },
  props: {
    heading: String,
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage;
    },
    endIndex() {
      return this.startIndex + this.perPage;
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      totalRecords: 10,
      users: [],
      showSkeleton: true,
      searchTimeout: null,
      selectedRows: [],
      headers: {
        username: {
          label: 'Username',
          sortable: true,
          searchable: true,
          minWidth: '30%',
        },
        email: {
          label: 'Email',
          sortable: true,
          searchable: true,
          minWidth: '30%',
        },
        verified: {
          label: 'Verified',
          sortable: true,
          minWidth: '10%',
        },
        points: {
          label: 'Points',
          sortable: true,
          searchable: true,
          minWidth: '10%',
        },
        optIn: {
          label: 'Opt-In',
          sortable: true,
          minWidth: '10%',
        },
      },
      excelColumns: [
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Date',
          value: (row) => new Date(row.updatedAt).toLocaleDateString('en-CA'),
        },
        {
          label: 'Language',
          value: 'locale',
        },
        {
          label: 'Opt-In',
          value: 'optIn',
        },
      ],
    };
  },
  created() {
    this.getUsers().finally(() => {
      this.showSkeleton = false;
    });
  },
  watch: {
    currentPage() {
      this.getUsers();
    },
  },
  methods: {
    search(result) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        if (result !== '') {
          ApiService.get(`/user?offset=0&search=${result}`).then((response) => {
            this.users = response.data.users;
            this.totalRecords = response.data.total;
          });
        } else {
          this.getUsers();
        }
      }, 250);
    },
    handleRowClick(event) {
      event.checked = !event.checked;
    },
    async getUsers() {
      await ApiService.get('/user', { offset: this.startIndex, limit: this.perPage })
        .then((response) => {
          this.users = response.data.users;
          this.totalRecords = response.data.total;
          Object.keys(this.users).forEach((key) => {
            this.users[key].points = this.users[key].userPoint.points;
          });
        })
        .catch((err) => {
          this.$notify({
            group: 'primary',
            type: 'error',
            text: `There was an error getting users: ${err.message}`,
          });
        });
    },
    async deleteRows(rows) {
      // if (!rows.length) return;
      if (!(await this.$refs.deleteModal.confirm())) return;
      const userIds = rows.map((row) => row.id);
      await Promise.all(
        userIds.map(async (userId) => {
          await ApiService.delete(`/user/${userId}`);
        }),
      );
      rows.forEach(({ id }) => {
        const index = this.users.findIndex((row) => row.id === id);
        if (index > -1) this.users.splice(index, 1);
      });
    },
    duplicateRow(row) {
      return row;
    },
    async downloadExcel() {
      const allUsersRes = await ApiService.get('/user', { offset: 0, limit: this.totalRecords });
      const { users } = allUsersRes.data;

      const data = [
        {
          sheet: 'Users',
          columns: this.excelColumns,
          content: users.filter((o) => o.optIn).sort((a, b) => a.updatedAt.localeCompare(b.updatedAt)),
        },
      ];

      const settings = {
        fileName: `BroncoEggHunt-Users-${new Date().toLocaleDateString('en-CA')}`, // BroncoEggHunt-yyyy-mm-dd
        extraLength: 3, // A bigger number means that columns will be wider
      };

      return xlsx(data, settings); // Will download the excel file
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-table {
  display: flex;
  flex-direction: column;
  width: 100%;

  .actions {
    align-self: flex-end;
  }

  .line-break {
    width: 1601px;
    margin-top: 3em;
    margin-bottom: 3em;
    opacity: 0.1;
  }
  .page-heading {
    display: flex;
    font-size: 28px;
    letter-spacing: -0.7px;
  }
  .checkmark {
    width: 20px;
    height: 20px;
    padding: 3px;
    border: 1px #222 solid;
    border-radius: 50%;
    path {
      fill: #222;
      stroke: #222;
    }
  }
  .x-icon {
    width: 20px;
    height: 20px;
    padding: 3px;
    border: 1px $red solid;
    border-radius: 50%;
  }
  .pagination {
    margin-top: 1em;
  }
}
</style>
